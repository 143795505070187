import DateFormat from "@/libs/DateFormat";

export default class ProductType {
  constructor(data = {}) {
    return {
      id: data.id || "",
      producttype: data.producttype || "",
      createddate: DateFormat.formatDate(data.createddate) || "",
    };
  }
}
