<template>
  <div class="list-margin">
    <DataTable
      :value="producttype"
      :paginator="true"
      :lazy="true"
      class="p-datatable-lg p-datatable-customers"
      :rows="itemsPerPage"
      :totalRecords="totalRecords"
      dataKey="userid"
      :rowHover="true"
      :resizableColumns="true"
      @page="onPage($event)"
      @sort="onSort($event)"
      paginatorTemplate="PrevPageLink PageLinks NextPageLink CurrentPageReport"
      currentPageReportTemplate="Showing {first} to {last} of {totalRecords} results"
    >
      <template #header>
        <div class="flex">
          <div class="col-6 text-left list-header">Product Type</div>
          <div class="col-6 text-right ml-4">
            <PrimeButton
              label=" ADD "
              class="add-button mr-3"
              icon="plus-icon"
              @click="gotoCreateProductType"
            ></PrimeButton>
            <div class="p-input-icon-right">
              <i class="search-icon cursor-pointer" @click="onSearch" />
              <InputText
                v-model="search"
                placeholder="Search"
                class="search-input"
              />
            </div>
          </div>
        </div>
      </template>
      <template #loading> Loading records, please wait... </template>
      <template #empty> No records found. </template>
      <Column
        field="producttype"
        header="Product Type"
        sortable="true"
      ></Column>
      <Column field="createddate" header="Created At" sortable="true"></Column>
      <Column field="action" header="Action">
        <template #body="data">
          <div class="flex">
            <PrimeButton
              label="Edit"
              class="edit-button ml-2"
              @click="gotoProductTypeEdit(data.data.id)"
            />
            <PrimeButton
              label="Delete"
              class="delete-button ml-2"
              @click="showDeletePopup(data.data.id)"
            />
          </div>
        </template>
      </Column>
    </DataTable>
    <div>
      <ConfirmPopup
        :show-popup="showPopup"
        @confirmYes="deleteProductType"
        @confirmNo="showPopup = false"
      ></ConfirmPopup>
    </div>
  </div>
</template>
<script>
import APIService from "@/services/api-service.js";
import ProductTypeFactory from "@/factories/producttype.js";
export default {
  data() {
    return {
      currentPage: 0,
      itemsPerPage: 10,
      search: "",
      totalRecords: 0,
      sortColumn: "id",
      sortDir: "asc",
      productTypeIdForDelete: "",
      showPopup: false,
      producttype: [],
      confirmType: "",
    };
  },
  mounted() {
    this.getProductTypeList();
  },
  methods: {
    gotoCreateProductType() {
      this.$router.push({ name: "ProductTypeCreate" });
    },
    gotoProductTypeEdit(productTypeId) {
      this.$router.push({
        name: "ProductTypeEdit",
        params: { id: productTypeId },
      });
    },
    showDeletePopup(productTypeId) {
      this.productTypeIdForDelete = productTypeId;
      this.showPopup = true;
    },
    getProductTypeList() {
      this.producttype = [];
      APIService.get(
        `/producttype/${this.currentPage}/${this.itemsPerPage}/${this.sortColumn}/${this.sortDir}/${this.search}`
      ).then((response) => {
        if (response && response.data) {
          this.totalRecords = response.data.totalRecords;
          this.producttype = ProductTypeFactory.createFromJsonArray(
            response.data.data
          );
        }
      });
    },
    deleteProductType() {
      APIService.delete(`/producttype/delete/${this.productTypeIdForDelete}`)
        .then((response) => {
          if (response && response.data && response.data.success) {
            this.getProductTypeList();
          } else {
            this.$toast.add({
              severity: "error",
              summary: "Error",
              detail: response.data.message,
              life: 3000,
            });
          }
        })
        .finally(() => {
          this.showPopup = false;
        });
    },
    onPage(pageData) {
      this.currentPage = pageData.first;
      this.getProductTypeList();
    },
    onSort(sortData) {
      let sortField = sortData.sortField;
      if (sortData.sortField === "createdby") {
        sortField = "createdby";
      } else if (sortData.sortField === "createddate") {
        sortField = "createddate";
      }
      this.sortColumn = sortField;
      this.sortDir = sortData.sortOrder === 1 ? "asc" : "dsc";
      this.currentPage = 0;

      this.getProductTypeList();
    },
    onSearch() {
      this.search = this.search.trimStart().trimEnd();
      this.currentPage = 0;
      this.getProductTypeList();
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/datatable.scss";
</style>
