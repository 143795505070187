/* eslint-disable vue/multi-word-component-names */
import { createApp } from "vue";
import App from "./App.vue";
import PrimeVue from "primevue/config";
import router from "./router";
import store from "./store";

import InputText from "primevue/inputtext";
import InputNumber from "primevue/inputnumber";
import InputNumberRequire from "@/components/form-control/input-number.vue";
import Textarea from "primevue/textarea";
import FileUpload from "primevue/fileupload";
import Button from "primevue/button";
import InputTextRequire from "@/components/form-control/input-text.vue";
import InputGroupRequire from "@/components/form-control/input-group.vue";
import InputGroupNumberRequire from "@/components/form-control/input-group-number.vue";
import TextareaRequire from "@/components/form-control/input-text-area.vue";
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import Editor from "primevue/editor";
import Menu from "primevue/menu";
import EditorRequire from "@/components/form-control/editor.vue";
import Toast from "primevue/toast";
import ToastService from "primevue/toastservice";
import Dropdown from "primevue/dropdown";
import DropDownRequire from "@/components/form-control/drop-down.vue";
import InputDate from "@/components/form-control/input-date.vue";
import Calendar from "primevue/calendar";
import moment from "moment-timezone";
import ConfirmationService from "primevue/confirmationservice";
import ConfirmPopup from "@/components/modal-popup/confirm-popup.vue";
// import ScanPopup from '@/components/modal-popup/scan-popup.vue'
import clickOutside from "@/components/directive/useDetectOutsideClick";

import "primevue/resources/themes/bootstrap4-light-blue/theme.css";
import "primevue/resources/primevue.min.css";
import "primeflex/primeflex.min.css";
import "primeicons/primeicons.css";
import "@/assets/css/global.css";

const app = createApp(App);
app.use(PrimeVue);
app.use(store);
app.use(ToastService);
app.use(moment);
app.use(ConfirmationService);

app.directive("click-outside", clickOutside);

app.component("InputText", InputText);
app.component("Menu", Menu);
app.component("Textarea", Textarea);
app.component("FileUpload", FileUpload);
app.component("PrimeButton", Button);
app.component("ConfirmPopup", ConfirmPopup);
// app.component('ScanPopup',ScanPopup)
app.component("InputTextRequire", InputTextRequire);
app.component("TextareaRequire", TextareaRequire);
app.component("DataTable", DataTable);
app.component("Column", Column);
app.component("Editor", Editor);
app.component("EditorRequire", EditorRequire);
app.component("PrimeToast", Toast);
app.component("Dropdown", Dropdown);
app.component("DropDownRequire", DropDownRequire);
app.component("InputDate", InputDate);
app.component("Calendar", Calendar);
app.component("InputNumber", InputNumber);
app.component("InputNumberRequire", InputNumberRequire);
app.component("InputGroupRequire", InputGroupRequire);
app.component("InputGroupNumberRequire", InputGroupNumberRequire);

app.use(router).mount("#app");
