<template>
  <div class="ml-6 mr-6 margin-top mb-6">
    <div class="grid">
      <div class="flex order-form-title">Question Management / {{ title }}</div>
      <div class="order-card w-full">
        <div class="col-12">
          <div class="grid">
            <div class="col-3">
              <div class="order-form-sub-title">Questionnaire Details</div>
            </div>
            <div class="col-9">
              <div class="grid">
                <div class="col-6">
                  <DropDownRequire
                    v-model="vmodel.type"
                    :validation="v$.vmodel.type"
                    :options="productTypeOptions"
                    sectionClass="field mt-1"
                    id="type"
                    label="Product Type"
                    placeholder="Product Type"
                    optionLabel="name"
                    optionValue="id"
                  />
                </div>
              </div>
              <div class="grid">
                <div class="col-12">
                  <InputTextRequire
                    v-model="vmodel.question"
                    :validation="v$.vmodel.question"
                    sectionClass="field mt-1"
                    id="username"
                    label="Question"
                    placeholder="Question"
                    maxlength="20"
                  />
                </div>
              </div>
              <label class="form-label block mb-2">Answer</label>
              <div
                class="grid"
                v-for="(answer, index) in vmodel.answers"
                :id="answer.id"
                :key="answer.id"
              >
                <div class="col-9">
                  <InputGroupRequire
                    v-model="vmodel.answers[index].answer"
                    :validation="
                      v$.vmodel.answers.$each.$response.$errors[index].answer[0]
                    "
                    sectionClass="p-inputgroup"
                    :id="`answer${index + 1}`"
                    :label="`Answer ${index + 1}`"
                    placeholder="Answer"
                    :customfield="true"
                    :validated="validated"
                  />
                </div>
                <div class="col-3">
                  <InputNumberRequire
                    v-model="vmodel.answers[index].score"
                    :validation="
                      v$.vmodel.answers.$each.$response.$errors[index].score[0]
                    "
                    placeholder="Score"
                    sectionClass="p-fluid w-full"
                    :id="`score${index + 1}`"
                    :min="1"
                    label="Score"
                    :customfield="true"
                    :validated="validated"
                  />
                </div>
              </div>
              <div class="mt-4">
                <PrimeButton
                  label="Add"
                  class="order-form-button"
                  v-if="!isEditMode"
                  @click="createQuestion"
                ></PrimeButton>
                <PrimeButton
                  label="Edit"
                  class="order-form-button"
                  v-else
                  @click="createQuestion"
                ></PrimeButton>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { useVuelidate } from "@vuelidate/core";
import { required, maxLength, helpers, maxValue } from "@vuelidate/validators";
import APIService from "@/services/api-service.js";

export default {
  setup() {
    return { v$: useVuelidate() };
  },
  data() {
    return {
      photo: "",
      submitted: false,
      isEditMode: false,
      title: "Add",
      productTypeOptions: [],
      validated: false,
      vmodel: {
        type: "",
        question: "",
        answers: [
          {
            answer: "",
            score: 0,
          },
          {
            answer: "",
            score: 0,
          },
          {
            answer: "",
            score: 0,
          },
        ],
      },
    };
  },
  validations() {
    return {
      vmodel: {
        type: { required },
        question: { required, max: maxLength(250) },
        answers: {
          $each: helpers.forEach({
            answer: { required, max: maxLength(100) },
            score: { required, maxValue:maxValue(100) },
          }),
        },
      },
    };
  },
  mounted() {
    if (this.$route.params.id) {
      this.title = "Edit";
      this.isEditMode = true;
      this.getQuestionById();
    }
    this.getProductType();
  },
  methods: {
    async createQuestion() {
      const isFormCorrect = await this.v$.$validate();
      this.validated = true;
      if (!isFormCorrect) return;
      else {
        let payload = {
          producttype: this.vmodel.type,
          question: this.vmodel.question,
          answers: this.vmodel.answers,
        };
        this.submitted = true;
        if (this.$route.params.id) {
          APIService.put("/questionmanagement", payload, this.$route.params.id)
            .then((response) => {
              if (response && response.data && response.data.success) {
                this.$toast.add({
                  severity: "success",
                  summary: "Success",
                  detail: response.data.message,
                  life: 3000,
                });
                setTimeout(() => {
                  this.$router.push({ name: "Question" });
                });
              } else {
                this.$toast.add({
                  severity: "error",
                  summary: "Error",
                  detail: response.data.message,
                  life: 3000,
                });
              }
            })
            .finally(() => {
              this.submitted = false;
            });
        } else {
          APIService.post("/questionmanagement", payload)
            .then((response) => {
              if (response && response.data && response.data.success) {
                this.$toast.add({
                  severity: "success",
                  summary: "Success",
                  detail: response.data.message,
                  life: 3000,
                });
                setTimeout(() => {
                  this.$router.push({ name: "Question" });
                });
              } else {
                this.$toast.add({
                  severity: "error",
                  summary: "Error",
                  detail: response.data.message,
                  life: 3000,
                });
              }
            })
            .finally(() => {
              this.submitted = false;
            });
        }
      }
    },
    async getQuestionById() {
      APIService.getById("/questionmanagement", this.$route.params.id)
        .then((response) => {
          if (response && response.data && response.data.data) {
            this.vmodel.type = response.data.data.producttype;
            this.vmodel.question = response.data.data.question;
            this.vmodel.answers = response.data.data.answers;
          } else {
            this.$toast.add({
              severity: "error",
              summary: "Error",
              detail: response.data.message,
              life: 3000,
            });
            setTimeout(() => {
              this.$router.push({ name: "Question" });
            });
          }
        })
        .finally(() => {
          this.submitted = false;
        });
    },
    getProductType() {
      APIService.get("/producttype/dropdown")
        .then((response) => {
          if (response && response.data && response.data.data) {
            this.productTypeOptions = response.data.data;
          } else {
            this.$toast.add({
              severity: "error",
              summary: "Error",
              detail: response.data.message,
              life: 3000,
            });
          }
        })
        .finally(() => {
          this.submitted = false;
        });
    },
  },
};
</script>
