import DateFormat from "@/libs/DateFormat";

export default class Question {
  constructor(data = {}) {
    return {
      id: data.id || "",
      question: data.question || "",
      producttype: data.producttype || "",
      createddate: DateFormat.formatDate(data.createddate) || "",
    };
  }
}
