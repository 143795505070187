import { createWebHistory, createRouter } from "vue-router";
import Store from "../store";
import MainLayout from "@/views/main-layout.vue";

import Login from "@/views/login.vue";
import ForgotPassword from "@/views/forgot-password.vue";
import ResetPassword from "@/views/reset-password.vue";
import Member from "@/views/Member/member-list.vue";
import MemberCreate from "@/views/Member/member-form.vue";
import ProductType from "@/views/Product-Type/product-type-list.vue";
import ProductTypeCreate from "@/views/Product-Type/product-type-form.vue";
import Questionnaire from "@/views/Quetionnaire/questionnaire-list.vue";
import QuestionnaireCreate from "@/views/Quetionnaire/questionnaire-form.vue";
import Question from "@/views/Question/question-list.vue";
import QuestionCreate from "@/views/Question/question-form.vue";
import Product from "@/views/Product-List/product-list.vue";
import ProductCreate from "@/views/Product-List/product-form.vue";
import MemberMatch from "@/views/Member-Matching/member-matching-list.vue";
import PrivacyPolicy from "@/views/PrivacyPolicyPage/privacy-policy.vue";
import TermsAndConditions from "@/views/TermsAndConditionsPage/terms-and-conditions.vue";

const routes = [
  {
    path: "/login",
    name: "Login",
    component: Login,
    meta: {
      title: "Login",
    },
  },
  {
    path: "/forgot-password",
    name: "ForgotPassword",
    component: ForgotPassword,
    meta: {
      title: "Forgot password",
    },
  },
  {
    path: "/reset-password",
    name: "ResetPassword",
    component: ResetPassword,
    meta: {
      title: "Reset Password",
    },
  },
  {
    component: MainLayout,
    children: [
      {
        path: "/member-management",
        name: "Member",
        component: Member,
        meta: {
          requiresAuth: true,
          module: "Member",
          title: "Member",
        },
      },
      {
        path: "/member-management/create",
        name: "MemberCreate",
        component: MemberCreate,
        meta: {
          requiresAuth: true,
          module: "Member",
          title: "Member Management / Add",
        },
      },
      {
        path: "/member-management/edit/:id",
        name: "MemberEdit",
        component: MemberCreate,
        meta: {
          requiresAuth: true,
          module: "Member",
          title: "Member Management / Edit",
        },
      },
      {
        path: "/product-type",
        name: "ProductType",
        component: ProductType,
        meta: {
          requiresAuth: true,
          module: "ProductType",
          title: "ProductType",
        },
      },
      {
        path: "/product-type/create",
        name: "ProductTypeCreate",
        component: ProductTypeCreate,
        meta: {
          requiresAuth: true,
          module: "ProductType",
          title: "Product Type / Add",
        },
      },
      {
        path: "/product-type/edit/:id",
        name: "ProductTypeEdit",
        component: ProductTypeCreate,
        meta: {
          requiresAuth: true,
          module: "ProductType",
          title: "Product Type / Edit",
        },
      },
      {
        path: "/questionnaire-management",
        name: "Questionnaire",
        component: Questionnaire,
        meta: {
          requiresAuth: true,
          module: "Questionnaire",
          title: "Questionnaire Management",
        },
      },
      {
        path: "/questionnaire-management/create",
        name: "QuestionnaireCreate",
        component: QuestionnaireCreate,
        meta: {
          requiresAuth: true,
          module: "Questionnaire",
          title: "Questionnaire Management / Add",
        },
      },
      {
        path: "/questionnaire-management/edit/:id",
        name: "QuestionnaireEdit",
        component: QuestionnaireCreate,
        meta: {
          requiresAuth: true,
          module: "Questionnaire",
          title: "Questionnaire Management / Edit",
        },
      },
      {
        path: "/question-management",
        name: "Question",
        component: Question,
        meta: {
          requiresAuth: true,
          module: "Question",
          title: "Question Management",
        },
      },
      {
        path: "/question-management/create",
        name: "QuestionCreate",
        component: QuestionCreate,
        meta: {
          requiresAuth: true,
          module: "Question",
          title: "Question Management / Add",
        },
      },
      {
        path: "/question-management/edit/:id",
        name: "QuestionEdit",
        component: QuestionCreate,
        meta: {
          requiresAuth: true,
          module: "Question",
          title: "Question Management / Edit",
        },
      },
      {
        path: "/product-list",
        name: "Product",
        component: Product,
        meta: {
          requiresAuth: true,
          module: "Product",
          title: "Product List",
        },
      },
      {
        path: "/product-list/create",
        name: "ProductCreate",
        component: ProductCreate,
        meta: {
          requiresAuth: true,
          module: "Product",
          title: "Product List / Add",
        },
      },
      {
        path: "/product-list/edit/:id",
        name: "ProductEdit",
        component: ProductCreate,
        meta: {
          requiresAuth: true,
          module: "Product",
          title: "Product List / Edit",
        },
      },
      {
        path: "/member-matching-record",
        name: "MemberMatch",
        component: MemberMatch,
        meta: {
          requiresAuth: true,
          module: "MemberMatch",
          title: "Member Matching Record",
        },
      },
      {
        path: "/privacy-policy",
        name: "PrivacyPolicy",
        component: PrivacyPolicy,
        meta: {
          requiresAuth: true,
          module: "PrivacyPolicy",
          title: "Privacy policy",
        },
      },
      {
        path: "/terms-conditions",
        name: "TermsAndConditions",
        component: TermsAndConditions,
        meta: {
          requiresAuth: true,
          module: "TermsAndConditions",
          title: "Terms and conditions",
        },
      },
    ],
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

// router.beforeEach((to) => {
//   Store.commit("setActiveRoute", to.meta.module);
// });

router.beforeEach((to, from, next) => {
  const title = "PRODUCT-MATCH-APP";
  if (
    Store.state.timeout == null ||
    (Store.state.timeout != null && Store.state.timeout > new Date())
  ) {
    if (to.matched.some((record) => record.meta.requiresAuth)) {
      if (Store.state.hasLoggedIn) {
        Store.commit("setUserTimeout");
        Store.commit("setActiveRoute", to.meta.module);
        window.document.title =
          to.meta && to.meta.title ? title + " - " + to.meta.title : title;
        next();
        return;
      }
      Store.dispatch("logout");
      next("/login");
      return;
    } else if (to.name === undefined) {
      if (Store.state.hasLoggedIn) {
        Store.commit("setActiveRoute", to.meta.module);
        window.document.title =
          to.meta && to.meta.title ? title + " - " + to.meta.title : title;
        next();
        return;
      }
      Store.dispatch("logout");
      next("/login");
      return;
    } else {
      Store.commit("setUserTimeout");
      if (to.name == "Login" && Store.state.hasLoggedIn) {
        Store.commit("setActiveRoute", "Member");
        next("/member-management");
        return;
      } else {
        window.document.title =
          to.meta && to.meta.title ? title + " - " + to.meta.title : title;
        next();
        return;
      }
    }
  } else {
    Store.dispatch("logout");
    next("/login");
    return;
  }
});

export default router;
