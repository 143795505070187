<template>
  <div class="sidebar">
    <div class="logo">
      <span><img src="../../assets/icons/logo.png" /></span>
    </div>
    <div class="menu">
      <router-link
        :to="{ name: 'Member' }"
        id="Member"
        :class="
          $store.state.activeRoute === 'Member'
            ? 'menu-item selected'
            : 'menu-item'
        "
      >
        <i
          :class="
            $store.state.activeRoute === 'Member'
              ? 'member-icon selected'
              : 'member-icon'
          "
        ></i>
        <span class="menu-text">Member Management</span>
      </router-link>
      <router-link
        :to="{ name: 'ProductType' }"
        id="ProductType"
        :class="
          $store.state.activeRoute === 'ProductType'
            ? 'menu-item selected'
            : 'menu-item'
        "
      >
        <i
          :class="
            $store.state.activeRoute === 'ProductType'
              ? 'product-type-icon selected'
              : 'product-type-icon'
          "
        ></i>
        <span class="menu-text">Product Type</span>
      </router-link>
      <router-link
        :to="{ name: 'Questionnaire' }"
        id="Questionnaire"
        :class="
          $store.state.activeRoute === 'Questionnaire'
            ? 'menu-item selected'
            : 'menu-item'
        "
      >
        <i
          :class="
            $store.state.activeRoute === 'Questionnaire'
              ? 'questionnaire-icon selected'
              : 'questionnaire-icon'
          "
        ></i>
        <span class="menu-text">Questionnaire Management</span>
      </router-link>
      <router-link
        :to="{ name: 'Question' }"
        id="Question"
        :class="
          $store.state.activeRoute === 'Question'
            ? 'menu-item selected'
            : 'menu-item'
        "
      >
        <i
          :class="
            $store.state.activeRoute === 'Question'
              ? 'question-icon selected'
              : 'question-icon'
          "
        ></i>
        <span class="menu-text">Question Management</span>
      </router-link>
      <router-link
        :to="{ name: 'Product' }"
        id="Product"
        :class="
          $store.state.activeRoute === 'Product'
            ? 'menu-item selected'
            : 'menu-item'
        "
      >
        <i
          :class="
            $store.state.activeRoute === 'Product'
              ? 'product-list-icon selected'
              : 'product-list-icon'
          "
        ></i>
        <span class="menu-text">Product List</span>
      </router-link>
      <router-link
        :to="{ name: 'MemberMatch' }"
        id="MemberMatch"
        :class="
          $store.state.activeRoute === 'MemberMatch'
            ? 'menu-item selected'
            : 'menu-item'
        "
      >
        <i
          :class="
            $store.state.activeRoute === 'MemberMatch'
              ? 'member-match-icon selected'
              : 'member-match-icon'
          "
        ></i>
        <span class="menu-text">Member Matching Record</span>
      </router-link>
      <div
        class="menu-item cursor-pointer"
        @click="visibleCMSMenu = !visibleCMSMenu"
      >
        <i class="cms-icon"></i>
        <span class="menu-text">CMS Page Management</span>
        <i
          class="ml-auto"
          :class="{
            'chevron-down-icon': !visibleCMSMenu,
            'chevron-up-icon': visibleCMSMenu,
          }"
        ></i>
      </div>
      <router-link
        v-if="visibleCMSMenu"
        :to="{ name: 'PrivacyPolicy' }"
        id="PrivacyPolicy"
        :class="
          $store.state.activeRoute === 'PrivacyPolicy'
            ? 'menu-item selected'
            : 'menu-item'
        "
      >
        <i
          :class="
            $store.state.activeRoute === 'PrivacyPolicy'
              ? 'page-icon selected'
              : 'page-icon'
          "
        ></i>

        <span class="menu-text">Privacy Policy Page</span>
      </router-link>

      <router-link
        v-if="visibleCMSMenu"
        :to="{ name: 'TermsAndConditions' }"
        id="TermsAndConditions"
        :class="
          $store.state.activeRoute === 'TermsAndConditions'
            ? 'menu-item selected'
            : 'menu-item'
        "
      >
        <i
          :class="
            $store.state.activeRoute === 'TermsAndConditions'
              ? 'page-icon selected'
              : 'page-icon'
          "
        ></i>
        <span class="menu-text">Terms & Conditions Page</span>
      </router-link>
    </div>
  </div>
</template>

<script>
export default {
  name: "SidebarMenu",
  data() {
    return {
      // username: "chantaiman12345",
      visibleLeft: true,
      visibleCMSMenu: false,
      visibleDDMenu: false,
    };
  },
  methods: {
    logout() {
      this.$store.dispatch("logout");
      this.$router.push({ name: "Login" });
    },
  },
};
</script>

<style scoped>
@import "@/assets/css/sidebar.css";
</style>
