import DateFormat from "@/libs/DateFormat";

export default class MemberMatch {
  constructor(data = {}) {
    return {
      id: data.id || "",
      date: DateFormat.formatDate(data.date) || "",
      userid: data.userid || "",
      username: data.username || "",
      producttype: data.producttype || "",
      totalscore:data.totalscore || "",
      productname:data.productname || "",
      createddate: DateFormat.formatDate(data.createddate) || "",
    };
  }
}
