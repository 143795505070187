<template>
  <div :class="sectionClass">
    <span
      class="p-inputgroup-addon"
      :class="{
        'p-invalid':
          ((validated && customfield && validation) ||
            (validation && validation.$error)) &&
          !removeClass,
      }"
      >{{ label }}</span
    >
    <InputText
      :id="id"
      :type="type"
      :value="modelValue"
      :disabled="disabled"
      :placeholder="placeholder"
      :class="{
        'p-invalid':
          (validated && customfield && validation) ||
          (validation && validation.$error),
      }"
      :aria-describedby="`${id}-help`"
      @input="$emit('update:modelValue', $event.target.value)"
    />
  </div>
  <span
    v-if="
      (validated && customfield && validation) ||
      (validation && validation.$error)
    "
    :id="`${id}-help`"
    class="p-error block text-left mt-2"
  >
    {{ validation.$message.replace("Value", this.label) }}
  </span>
</template>

<script>
export default {
  data() {
    return {
      removeClass: false,
    };
  },
  props: {
    sectionClass: {
      type: String,
      default: "field",
    },
    id: {
      type: String,
      required: true,
      default: "",
    },
    type: {
      type: String,
      default: "text",
    },
    label: {
      type: String,
      required: true,
      default: "",
    },
    validation: {
      type: Object,
      required: true,
    },
    minlength: {
      type: String,
      default: "",
    },
    maxlength: {
      type: String,
      default: "",
    },
    modelValue: {
      type: String,
      default: "",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    placeholder: {
      type: String,
      default: "",
    },
    customfield: {
      type: Boolean,
      default: false,
    },
    validated: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
