<template>
  <div class="ml-8 mr-8 mt-5 mb-5">
    <div class="grid">
      <div class="flex order-form-title">{{ title }}</div>
      <div class="order-card w-full">
        <div class="col-12">
          <div class="grid">
            <div class="col-4">
              <div class="order-form-sub-title">Privacy Policy Page Detail</div>
            </div>
            <div class="col-8">
              <span class="content">Content</span>
              <div v-if="dataLoaded">
                <EditorRequire v-model="vmodel.content" />
              </div>
              <div class="mt-4">
                <PrimeButton
                  label="Edit"
                  class="order-form-button"
                  @click="privacyPolicyEdit"
                ></PrimeButton>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import APIService from "@/services/api-service.js";
export default {
  data() {
    return {
      title: "Privacy Policy Page",
      dataLoaded: false,
      vmodel: {
        content: "",
      },
    };
  },
  mounted() {
    if (this.$route.name) {
      this.getPrivacyPolicyId();
    } else {
      this.dataLoaded = true;
    }
  },
  methods: {
    async getPrivacyPolicyId() {
      APIService.getById("/cmspages", this.$route.name)
        .then((response) => {
          if (response && response.data && response.data.data) {
            this.vmodel.content = response.data.data.content;
          } else {
            this.$toast.add({
              severity: "error",
              summary: "Error",
              detail: response.data.message,
              life: 3000,
            });
          }
        })
        .finally(() => {
          this.submitted = false;
          this.dataLoaded = true;
        });
    },
    async privacyPolicyEdit() {
      let payload = {
        content: this.vmodel.content,
      };
      if (this.$route.name) {
        APIService.patch("/cmspages", payload, this.$route.name)
          .then((response) => {
            if (response && response.data && response.data.success) {
              this.$toast.add({
                severity: "success",
                summary: "Success",
                detail: response.data.message,
                life: 3000,
              });
            }
          })
          .finally(() => {
            this.submitted = false;
          });
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.order-form-sub-title {
  font-weight: 500 !important;
  font-size: 18px !important;
  columns: #191e21 !important;
  text-transform: capitalize;
}
</style>
