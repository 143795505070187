<template>
  <div :class="sectionClass">
    <span
      class="p-inputgroup-addon set-margin"
      :class="{
        'p-invalid': validation?.$error && !removeClass,
      }"
      >{{ label }}</span
    >
    <InputNumber
      :id="id"
      :modelValue="modelValue"
      :disabled="disabled"
      :placeholder="placeholder"
      :min="min"
      :max="max"
      :useGrouping="useGrouping"
      :class="{
        'p-invalid': validation.$error,
      }"
      :aria-describedby="`${id}-help`"
      @input="$emit('update:modelValue', $event.value)"
    />
  </div>
  <span
    v-if="
      validation?.$error && validation.required && validation.required.$invalid
    "
    :id="`${id}-help`"
    class="p-error block text-left mt-2"
  >
    {{ validation.required.$message.replace("Value", this.label) }}
  </span>
  <span
    v-else-if="validation?.$error && validation.min && validation.min.$invalid"
    :id="`${id}-help`"
    class="p-error block text-left mt-2"
  >
    {{ validation.min.$message }}
  </span>
  <span
    v-else-if="validation?.$error && validation.max && validation.max.$invalid"
    :id="`${id}-help`"
    class="p-error block text-left mt-2"
  >
    {{ validation.max.$message }}
  </span>
</template>

<script>
export default {
  data() {
    return {
      removeClass: false,
    };
  },
  props: {
    sectionClass: {
      type: String,
      default: "field",
    },
    id: {
      type: String,
      required: true,
      default: "",
    },
    label: {
      type: String,
      required: true,
      default: "",
    },
    validation: {
      type: Object,
      required: true,
    },
    min: {
      type: Number,
      default: null,
    },
    max: {
      type: Number,
      default: null,
    },
    modelValue: {
      type: Number,
      default: null,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    placeholder: {
      type: String,
      default: "",
    },
    useGrouping: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
