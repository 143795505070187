import Member from "@/models/member";

export default class MemberFactory {
  static createFromJson(json) {
    return new Member(json);
  }

  static createFromJsonArray(jsonArray) {
    const jsonData = [];
    jsonArray.forEach((item) => {
      jsonData.push(MemberFactory.createFromJson(item));
    });

    return jsonData;
  }
}
