import DateFormat from "@/libs/DateFormat";

export default class Product {
  constructor(data = {}) {
    return {
      id: data.id || "",
      productname: data.productname || "",
      producttype: data.producttype || "",
      description: data.description || "",
      createddate: DateFormat.formatDate(data.createddate) || "",
    };
  }
}
