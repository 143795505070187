<template>
  <div class="ml-6 mr-6 margin-top mb-6">
    <div class="grid">
      <div class="flex order-form-title">Member Management / {{ title }}</div>
      <div class="order-card w-full">
        <div class="col-12">
          <div class="grid">
            <div class="col-3">
              <div class="order-form-sub-title">User Details</div>
            </div>
            <div class="col-9">
              <div class="grid">
                <div class="col-6">
                  <InputTextRequire
                    v-model="vmodel.userid"
                    sectionClass="field mt-1"
                    id="userid"
                    :validation="v$.vmodel.userid"
                    label="User ID"
                    class="disable-text-field"
                    placeholder="User ID"
                    :disabled="true"
                  />
                </div>
                <div class="col-6">
                  <InputTextRequire
                    v-model="vmodel.username"
                    :validation="v$.vmodel.username"
                    sectionClass="field mt-1"
                    id="username"
                    label="Username"
                    placeholder="Username"
                    maxlength="50"
                  />
                </div>
              </div>
              <div class="grid">
                <div class="col-6">
                  <InputTextRequire
                    v-model="vmodel.phoneno"
                    :validation="v$.vmodel.phoneno"
                    sectionClass="field mt-1"
                    id="phoneno"
                    label="Phone"
                    placeholder="Phone"
                    maxlength="8"
                    minlength="8"
                    @keypress="onlyNumber"
                  />
                </div>
                <div class="col-6">
                  <InputTextRequire
                    v-model="vmodel.email"
                    :validation="v$.vmodel.email"
                    sectionClass="field mt-1"
                    id="email"
                    label="Email"
                    placeholder="Email"
                    maxlength="50"
                  />
                </div>
              </div>
              <div class="mt-4">
                <PrimeButton
                  label="Add"
                  class="order-form-button"
                  v-if="!isEditMode"
                  @click="createMember"
                ></PrimeButton>
                <PrimeButton
                  label="Edit"
                  class="order-form-button"
                  v-else
                  @click="createMember"
                ></PrimeButton>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { useVuelidate } from "@vuelidate/core";
import {
  required,
  email,
  maxLength,
  minLength,
  numeric,
} from "@vuelidate/validators";
import APIService from "@/services/api-service.js";

export default {
  setup() {
    return { v$: useVuelidate() };
  },
  data() {
    return {
      photo: "",
      submitted: false,
      isEditMode: false,
      title: "Add",
      vmodel: {
        userid: "",
        username: "",
        email: "",
        phoneno: "",
      },
    };
  },
  validations() {
    return {
      vmodel: {
        userid: {},
        username: { required, max: maxLength(50) },
        email: { required, email, max: maxLength(50) },
        phoneno: { required, min: minLength(8), max: maxLength(8), numeric },
      },
    };
  },
  mounted() {
    if (this.$route.params.id) {
      this.title = "Edit";
      this.isEditMode = true;
      this.getMemberById();
    } else {
      this.vmodel.userid = Math.floor(10000 + Math.random() * 90000).toString();
    }
  },
  methods: {
    onlyNumber($event) {
      let keyCode = $event.keyCode ? $event.keyCode : $event.which;
      if (keyCode < 48 || keyCode > 57) {
        // 46 is dot
        $event.preventDefault();
      }
    },
    async createMember() {
      const isFormCorrect = await this.v$.$validate();
      if (!isFormCorrect) return;
      else {
        let payload = {
          userid: this.vmodel.userid,
          username: this.vmodel.username,
          email: this.vmodel.email,
          phoneno: this.vmodel.phoneno,
        };
        this.submitted = true;
        if (this.$route.params.id) {
          APIService.put("/member", payload, this.$route.params.id)
            .then((response) => {
              if (response && response.data && response.data.success) {
                this.$toast.add({
                  severity: "success",
                  summary: "Success",
                  detail: response.data.message,
                  life: 3000,
                });
                setTimeout(() => {
                  this.$router.push({ name: "Member" });
                });
              } else {
                this.$toast.add({
                  severity: "error",
                  summary: "Error",
                  detail: response.data.message,
                  life: 3000,
                });
              }
            })
            .finally(() => {
              this.submitted = false;
            });
        } else {
          APIService.post("/member", payload)
            .then((response) => {
              if (response && response.data && response.data.success) {
                this.$toast.add({
                  severity: "success",
                  summary: "Success",
                  detail: response.data.message,
                  life: 3000,
                });
                setTimeout(() => {
                  this.$router.push({ name: "Member" });
                });
              } else {
                this.$toast.add({
                  severity: "error",
                  summary: "Error",
                  detail: response.data.message,
                  life: 3000,
                });
              }
            })
            .finally(() => {
              this.submitted = false;
            });
        }
      }
    },
    async getMemberById() {
      APIService.getById("/member", this.$route.params.id)
        .then((response) => {
          if (response && response.data && response.data.data) {
            this.vmodel.userid = response.data.data.userid;
            this.vmodel.username = response.data.data.username;
            this.vmodel.email = response.data.data.email;
            this.vmodel.phoneno = response.data.data.phoneno;
          } else {
            this.$toast.add({
              severity: "error",
              summary: "Error",
              detail: response.data.message,
              life: 3000,
            });
            setTimeout(() => {
              this.$router.push({ name: "Member" });
            });
          }
        })
        .finally(() => {
          this.submitted = false;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep(.disable-text-field) {
  .p-inputtext {
    background: #cccccc !important;
    border: 1px solid rgba(0, 0, 0, 0.2) !important;
  }
}
::v-deep(.change-label) {
  .p-button-label {
    margin-left: -5px;
  }
}
</style>
