<template>
  <div class="ml-6 mr-6 margin-top mb-6">
    <div class="grid">
      <div class="flex order-form-title">Product List / {{ title }}</div>
      <div class="order-card w-full">
        <div class="col-12">
          <div class="grid">
            <div class="col-3">
              <div class="order-form-sub-title">Product Details</div>
            </div>
            <div class="col-9">
              <div class="grid">
                <div class="col-6">
                  <div class="field">
                    <label for="note" class="form-label">Image</label>
                    <div :class="vmodel.image ? 'remove-padding' : ''">
                      <FileUpload
                        ref="photo"
                        name="image"
                        accept=".jpg, .png, .jpeg"
                        @select="onImageUpload($event)"
                        @upload="onImageUpload($event)"
                        @mouseover="mouseOver"
                        @mouseleave="mouseLeave"
                        :aria-describedby="`image-help`"
                      >
                        <template #content>
                          <div
                            class="flex align-items-center justify-content-center flex-column header-section cursor-pointer"
                            
                          >
                            <span
                              class="upload-icon"
                              v-if="vmodel.image == ''"
                              @click="openFileUpload"
                            ></span>
                            <div class="upload-text" v-if="vmodel.image == ''" @click="openFileUpload">
                              Drag and Drop files here or click
                            </div>
                          </div>
                          <div class="photo-section">
                            <img
                              v-if="vmodel.image !== ''"
                              :src="vmodel.image"
                              alt=""
                              class="upload-img"
                              height="160"
                              width="160"
                              style="border-radius: 1px"
                            />
                            <div class="overlay" v-show="active">
                              <div
                                class="grid m-0 align-items-center justify-content-center"
                              >
                                <PrimeButton
                                  label="Remove"
                                  class="remove-button"
                                  @click="removeImage"
                                ></PrimeButton>
                                <div
                                  class="image-name col-12"
                                >
                                  {{ imagename }}
                                </div>
                                <div
                                  class="text col-12 cursor-pointer"
                                  @click="openFileUpload"
                                >
                                  Drag and Drop or click to replace
                                </div>
                              </div>
                            </div>
                          </div>
                        </template>
                      </FileUpload>
                    </div>
                    <div
                      v-if="
                        v$.vmodel.image.$error &&
                        v$.vmodel.image.required &&
                        v$.vmodel.image.required.$invalid
                      "
                      :id="`image-help`"
                      class="p-error text-left mt-2"
                    >
                      {{
                        v$.vmodel.image.required.$message.replace(
                          "Value",
                          "Image"
                        )
                      }}
                    </div>
                  </div>
                </div>
              </div>
              <div class="grid">
                <div class="col-6">
                  <DropDownRequire
                    v-model="vmodel.type"
                    :validation="v$.vmodel.type"
                    :options="productTypeOptions"
                    sectionClass="field mt-1"
                    id="type"
                    label="Product Type"
                    placeholder="Product Type"
                    optionLabel="name"
                    optionValue="id"
                  />
                </div>
                <div class="col-6">
                  <InputTextRequire
                    v-model="vmodel.name"
                    :validation="v$.vmodel.name"
                    sectionClass="field mt-1"
                    id="name"
                    label="Product Name"
                    placeholder="Product Name"
                    maxlength="30"
                  />
                </div>
              </div>
              <div class="grid">
                <div class="col-12">
                  <InputTextRequire
                    v-model="vmodel.description"
                    :validation="v$.vmodel.description"
                    sectionClass="field mt-1"
                    id="description"
                    label="Description"
                    placeholder="Description"
                    maxlength="300"
                  />
                </div>
              </div>
              <label class="form-label">Score Range</label>
              <div class="grid mt-1">
                <div class="col-3">
                  <InputGroupNumberRequire
                    v-model="vmodel.from"
                    :validation="v$.vmodel.from"
                    sectionClass="p-inputgroup"
                    id="from"
                    label="From"
                    placeholder="Score"
                    :min="1"
                  />
                </div>
                <div class="col-3">
                  <InputGroupNumberRequire
                    v-model="vmodel.to"
                    :validation="v$.vmodel.to"
                    sectionClass="p-inputgroup"
                    id="to"
                    label="To"
                    placeholder="Score"
                    :min="1"
                  />
                </div>
              </div>
              <div class="mt-4">
                <PrimeButton
                  label="Add"
                  class="order-form-button"
                  v-if="!isEditMode"
                  @click="createProduct"
                ></PrimeButton>
                <PrimeButton
                  label="Edit"
                  class="order-form-button"
                  v-else
                  @click="createProduct"
                ></PrimeButton>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import APIService from "@/services/api-service.js";
import { useVuelidate } from "@vuelidate/core";
import { maxLength, maxValue, required } from "@vuelidate/validators";

export default {
  setup() {
    return { v$: useVuelidate() };
  },
  data() {
    return {
      photo: "",
      submitted: false,
      isEditMode: false,
      title: "Add",
      active: false,
      productTypeOptions: [],
      image: "",
      imagename: "",
      vmodel: {
        image: "",
        type: "",
        name: "",
        description: "",
        from: 0,
        to: 0,
      },
    };
  },
  validations() {
    return {
      vmodel: {
        image: { required },
        type: { required },
        name: { required, max: maxLength(50) },
        description: { required, max: maxLength(300) },
        from: { required, max: maxValue(100) },
        to: { required, max: maxValue(100) },
      },
    };
  },
  mounted() {
    if (this.$route.params.id) {
      this.title = "Edit";
      this.isEditMode = true;
      this.getProductById();
    }
    this.getProductType();
  },
  methods: {
    openImageUpload() {
      this.$refs.photo.choose();
    },
    mouseOver() {
      if (this.vmodel.image !== "") {
        this.active = true;
      }
    },
    mouseLeave() {
      if (this.vmodel.image !== "") {
        this.active = false;
      }
    },
    removeImage() {
      this.vmodel.image = "";
      this.image = "";
      this.active = false;
    },
    onImageUpload(fileObj) {
      if (
        !fileObj ||
        (fileObj && !fileObj.files) ||
        (fileObj && fileObj.files && fileObj.files.length === 0)
      ) {
        this.$toast.add({
          severity: "error",
          summary: "Error",
          detail: "Please select valid Image.",
          life: 3000,
        });
        this.$refs.noteDocs.clear();
      } else if (fileObj && fileObj.files && fileObj.files[0].size > 1000000) {
        this.$toast.add({
          severity: "error",
          summary: "Error",
          detail: "Image should be less then 1 MB",
          life: 3000,
        });
        this.$refs.photo.clear();
      } else {
        let formData = new FormData();
        formData.append("file", fileObj.files[0]);
        APIService.post("/product/product", formData, null, true)
          .then((response) => {
            if (response && response.data && response.data.success) {
              this.image = response.data.data.name;
              this.imagename = response.data.data.originalname;
              this.vmodel.image = response.data.data.path;
              this.$toast.add({
                severity: "success",
                summary: "Success",
                detail: response.data.message,
                life: 3000,
              });
            } else {
              this.$toast.add({
                severity: "error",
                summary: "Error",
                detail: response.data.message,
                life: 3000,
              });
            }
          })
          .finally(() => {
            this.submitted = false;
            this.$refs.photo.clear();
          });
      }
    },
    openFileUpload() {
      this.$refs.photo.choose();
    },
    async createProduct() {
      const isFormCorrect = await this.v$.$validate();
      if (!isFormCorrect) return;
      else {
        if (this.vmodel.to < this.vmodel.from) {
          this.$toast.add({
            severity: "error",
            summary: "Error",
            detail: "In Score Range, From score must be less than To score.",
            life: 3000,
          });
          return;
        }
        let payload = {
          image: this.image,
          name: this.vmodel.name,
          originalname: this.imagename,
          producttype: this.vmodel.type,
          description: this.vmodel.description,
          from: this.vmodel.from,
          to: this.vmodel.to,
        };
        this.submitted = true;
        if (this.$route.params.id) {
          APIService.put("/product", payload, this.$route.params.id)
            .then((response) => {
              if (response && response.data && response.data.success) {
                this.$toast.add({
                  severity: "success",
                  summary: "Success",
                  detail: response.data.message,
                  life: 3000,
                });
                setTimeout(() => {
                  this.$router.push({ name: "Product" });
                });
              } else {
                this.$toast.add({
                  severity: "error",
                  summary: "Error",
                  detail: response.data.message,
                  life: 3000,
                });
              }
            })
            .finally(() => {
              this.submitted = false;
            });
        } else {
          APIService.post("/product", payload)
            .then((response) => {
              if (response && response.data && response.data.success) {
                this.$toast.add({
                  severity: "success",
                  summary: "Success",
                  detail: response.data.message,
                  life: 3000,
                });
                setTimeout(() => {
                  this.$router.push({ name: "Product" });
                });
              } else {
                this.$toast.add({
                  severity: "error",
                  summary: "Error",
                  detail: response.data.message,
                  life: 3000,
                });
              }
            })
            .finally(() => {
              this.submitted = false;
            });
        }
      }
    },
    async getProductById() {
      APIService.getById("/product", this.$route.params.id)
        .then((response) => {
          if (response && response.data && response.data.data) {
            this.image = response.data.data.image;
            this.vmodel.image = response.data.data.imagepath;
            this.imagename = response.data.data.originalname;
            this.vmodel.type = response.data.data.producttype;
            this.vmodel.name = response.data.data.name;
            this.vmodel.description = response.data.data.description;
            this.vmodel.from = response.data.data.from;
            this.vmodel.to = response.data.data.to;
          } else {
            this.$toast.add({
              severity: "error",
              summary: "Error",
              detail: response.data.message,
              life: 3000,
            });
            setTimeout(() => {
              this.$router.push({ name: "Product" });
            });
          }
        })
        .finally(() => {
          this.submitted = false;
        });
    },
    getProductType() {
      APIService.get("/producttype/dropdown")
        .then((response) => {
          if (response && response.data && response.data.data) {
            this.productTypeOptions = response.data.data;
          } else {
            this.$toast.add({
              severity: "error",
              summary: "Error",
              detail: response.data.message,
              life: 3000,
            });
          }
        })
        .finally(() => {
          this.submitted = false;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/css/upload-image.css";
::v-deep(.remove-padding) {
  .p-fileupload {
    padding: 0px !important;
  }
}
</style>
