<template>
  <div class="list-margin">
    <DataTable
      :value="memberMatch"
      :paginator="true"
      :lazy="true"
      class="p-datatable-lg p-datatable-customers"
      :rows="itemsPerPage"
      :totalRecords="totalRecords"
      dataKey="userid"
      :rowHover="true"
      :resizableColumns="true"
      @page="onPage($event)"
      @sort="onSort($event)"
      paginatorTemplate="PrevPageLink PageLinks NextPageLink CurrentPageReport"
      currentPageReportTemplate="Showing {first} to {last} of {totalRecords} results"
    >
      <template #header>
        <div class="flex">
          <div class="col-6 text-left list-header">Member Matching Record</div>
          <div class="col-6 text-right ml-4">
            <div class="p-input-icon-right">
              <i class="search-icon cursor-pointer" @click="onSearch" />
              <InputText
                v-model="search"
                placeholder="Search"
                class="search-input"
              />
            </div>
          </div>
        </div>
      </template>
      <template #loading> Loading records, please wait... </template>
      <template #empty> No records found. </template>
      <Column field="date" header="Date" sortable="true"></Column>
      <Column field="userid" header="User ID" sortable="true"></Column>
      <Column field="username" header="Username" sortable="true"> </Column>
      <Column field="producttype" header="Product Type" sortable="true"></Column>
      <Column field="totalscore" header="Total Score" sortable="true"></Column>
      <Column
        field="productname"
        header="Recommended Product"
        sortable="true"
      ></Column>
    </DataTable>
  </div>
</template>
<script>
import APIService from "@/services/api-service.js";
import MemberMatchFactory from "@/factories/memberMatch.js";
export default {
  data() {
    return {
      currentPage: 0,
      itemsPerPage: 10,
      search: "",
      totalRecords: 0,
      sortColumn: "id",
      sortDir: "asc",
      memberMatch: [],
    };
  },
  mounted() {
    this.getMemberMatchList();
  },
  methods: {
    getMemberMatchList() {
      this.memberMatch = [];
      APIService.get(
        `/product/recommendation/${this.currentPage}/${this.itemsPerPage}/${this.sortColumn}/${this.sortDir}/${this.search}`
      ).then((response) => {
        if (response && response.data) {
          this.totalRecords = response.data.totalRecords;
          this.memberMatch = MemberMatchFactory.createFromJsonArray(
            response.data.data
          );
        }
      });
    },
    onPage(pageData) {
      this.currentPage = pageData.first;
      this.getMemberMatchList();
    },
    onSort(sortData) {
      let sortField = sortData.sortField;
      if (sortData.sortField === "createdby") {
        sortField = "createdby";
      } else if (sortData.sortField === "createddate") {
        sortField = "createddate";
      }
      this.sortColumn = sortField;
      this.sortDir = sortData.sortOrder === 1 ? "asc" : "dsc";
      this.currentPage = 0;

      this.getMemberMatchList();
    },
    onSearch() {
      this.search = this.search.trimStart().trimEnd();
      this.currentPage = 0;
      this.getMemberMatchList();
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/datatable.scss";
</style>
