import { createStore } from "vuex";
import createPersistedState from "vuex-persistedstate";

export default createStore({
    state: {
        hasLoggedIn: false,
        timeout: null,
        activeRoute: '',
        userInfo: {},
        userToken: null,
        showLoader: false,
        idForResetPassword: "",
    },
    plugins: [createPersistedState({ paths: ['hasLoggedIn', 'timeout', 'userInfo', 'userToken'] })],
    actions: {
        login({ commit }) {
            commit('setLoggedIn', true);
            return new Promise((resolve) => {
                commit('setLoggedIn', true);
                commit('setUserTimeout');
                resolve()
            })
        },
        logout({ commit }) {
            return new Promise((resolve) => {
                commit('clearUserTimeout');
                commit('setLoggedIn', false);
                resolve()
            })
        }
    },
    mutations: {
        setLoggedIn(state, value) {
            state.hasLoggedIn = value
        },
        setActiveRoute(state, value) {
            state.activeRoute = value
        },
        setUserTimeout(state) {
            state.timeout = new Date().setMinutes(new Date().getMinutes() + 30);
        },
        clearUserTimeout(state) {
            state.timeout = null;
        },
        setUserInfo(state, value) {
            state.userInfo = value;
        },
        setUserToken(state, value) {
            state.userToken = value
        },
        setIdForResetPassword(state, value) {
            state.idForResetPassword = value
        },
    },
});