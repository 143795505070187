<template>
  <div :class="sectionClass">
    <label :for="id" class="form-label">{{ label }}</label>
    <div class="p-inputgroup">
    <Calendar
      inputId="basic"
      :id="id"
      :type="type"
      :placeholder="placeholder"
      :disabled="disabled"
      :disabledDays="disabledDays"
      :disabledDates="disabledDates"
      :manualInput="manualInput"
      :modelValue="modelValue"
      :minDate="minDate"
      :class="{
        'p-invalid': validation?.$error,
      }"
      :aria-describedby="`${id}-help`"
      dateFormat="yyyy-mm-dd"
      @date-select="$emit('update:modelValue', formatDate($event))"
    />
    <span class="p-inputgroup-addon" 
        :class="{
          'invalid-icon': validation.$error,
        }">
        <span class="calendar-icon"></span>
      </span>
    </div>
    <span
      v-if="
        validation?.$error && validation.required && validation.required.$invalid
      "
      :id="`${id}-help`"
      class="p-error text-left mt-2"
    >
      {{ validation.required.$message.replace("Value", this.label) }}
    </span>
  </div>
</template>

<script>
import moment from "moment";

export default {
  props: {
    sectionClass: {
      type: String,
      default: "field",
    },
    id: {
      type: String,
      required: true,
      default: "",
    },
    type: {
      type: String,
      default: "text",
    },
    label: {
      type: String,
      required: true,
      default: "",
    },
    validation: {
      type: Object,
      required: true,
    },
    minlength: {
      type: String,
      default: "",
    },
    maxlength: {
      type: String,
      default: "",
    },
    modelValue: {
      type: String,
      default: "",
    },
    placeholder: {
      type: String,
      default: "",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    disabledDates: {
      type: Array,
      default: null,
    },
    disabledDays: {
      type: Array,
      default: null,
    },
    manualInput: {
      type: Boolean,
      default: true,
    },
    minDate: {
      type: Date,
      default: null,
    }
  },
  methods: {
    formatDate(value) {
      return moment(value).format("YYYY-MM-DD");
    },
  },
};
</script>
