import DateFormat from "@/libs/DateFormat";

export default class Questionnaire {
  constructor(data = {}) {
    return {
      id: data.id || "",
      quationnairename: data.quationnairename || "",
      producttype: data.producttype || "",
      description: data.description || "",
      createddate: DateFormat.formatDate(data.createddate) || "",
    };
  }
}
