import Product from "@/models/product";

export default class ProductFactory {
  static createFromJson(json) {
    return new Product(json);
  }

  static createFromJsonArray(jsonArray) {
    const jsonData = [];
    jsonArray.forEach((item) => {
      jsonData.push(ProductFactory.createFromJson(item));
    });

    return jsonData;
  }
}
