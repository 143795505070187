<template>
  <div class="main-container">
    <div class="login-form">
      <div class="form-container form-container-reset">
        <div class="login-logo">
          <img src="../assets/icons/logo.png" />
        </div>
        <div class="form-title margin">Reset Password</div>
        <div class="form-inputs">
          <div class="field mt-3">
            <label for="newpassword" class="form-label margin"
              >New Password</label
            >
            <InputText
              id="newpassword"
              type="newpassword"
              placeholder="New Password"
              v-model="vmodel.password"
              :class="{
                'p-invalid': v$.vmodel.password.$error,
              }"
              class="w-full margin"
            />
            <span
              v-if="
                v$.vmodel.password.$error &&
                v$.vmodel.password.required.$invalid
              "
              id="password-help"
              class="p-error text-left mt-2 ml-4"
            >
              {{
                v$.vmodel.password.required.$message.replace(
                  "Value",
                  "New Password"
                )
              }}
            </span>
            <span
              v-else-if="
                v$.vmodel.password.$error && v$.vmodel.password.min.$invalid
              "
              id="password-help"
              class="p-error text-left mt-2 ml-4"
            >
              {{ v$.vmodel.password.min.$message }}
            </span>
            <span
              v-else-if="
                v$.vmodel.password.$error && v$.vmodel.password.max.$invalid
              "
              id="password-help"
              class="p-error text-left mt-2 ml-4"
            >
              {{ v$.vmodel.password.max.$message }}
            </span>
            <div class="field mt-4">
              <label for="confirmpassword" class="form-label margin float-left"
                >Confirm New Password</label
              >
              <InputText
                id="confirmpassword"
                type="confirmpassword"
                placeholder="Confirm New Password"
                v-model="vmodel.confirmpassword"
                :class="{
                  'p-invalid': v$.vmodel.confirmpassword.$error,
                }"
                class="w-full margin"
              />
              <span
                v-if="
                  v$.vmodel.confirmpassword.$error &&
                  v$.vmodel.confirmpassword.required.$invalid
                "
                id="password-help"
                class="p-error text-left mt-2 ml-4"
              >
                {{
                  v$.vmodel.confirmpassword.required.$message.replace(
                    "Value",
                    "Confirm New Password"
                  )
                }}
              </span>
              <span
                v-else-if="
                  v$.vmodel.confirmpassword.$error &&
                  v$.vmodel.confirmpassword.min.$invalid
                "
                id="password-help"
                class="p-error text-left mt-2 ml-4"
              >
                {{ v$.vmodel.confirmpassword.min.$message }}
              </span>
              <span
                v-else-if="
                  v$.vmodel.confirmpassword.$error &&
                  v$.vmodel.confirmpassword.max.$invalid
                "
                id="password-help"
                class="p-error text-left mt-2 ml-4"
              >
                {{ v$.vmodel.confirmpassword.max.$message }}
              </span>
              <span
                v-else-if="
                  v$.vmodel.confirmpassword.$error &&
                  v$.vmodel.confirmpassword.sameAs.$invalid
                "
                id="password-help"
                class="p-error text-left mt-2 ml-4"
              >
                {{ v$.vmodel.confirmpassword.sameAs.$message }}
              </span>
            </div>
          </div>
          <div class="field mt-3 mb-2">
            <PrimeButton
              class="prime-button btn-login margin"
              label="RESET PASSWORD"
              @click="submitForm"
            ></PrimeButton>
            <div class="remember">
              Remember it?
              <label
                class="remember-login ml-2 cursor-pointer"
                @click="gotoLogin"
                >Login</label
              >
            </div>
          </div>
        </div>
      </div>
    </div>
    <PrimeToast />
  </div>
</template>

<script>
import APIService from "@/services/api-service.js";
import { useVuelidate } from "@vuelidate/core";
import { required, maxLength, minLength, sameAs } from "@vuelidate/validators";

export default {
  setup() {
    return { v$: useVuelidate() };
  },
  data() {
    return {
      vmodel: {
        password: null,
        confirmpassword: null,
      },
      submitted: false,
    };
  },
  validations() {
    return {
      vmodel: {
        password: { required, max: maxLength(20), min: minLength(8) },
        confirmpassword: {
          required,
          max: maxLength(20),
          min: minLength(8),
          sameAs: sameAs(this.vmodel.password),
        },
      },
    };
  },
  methods: {
    gotoLogin() {
      this.$router.push({ name: "Login" });
    },

    async submitForm() {
      const isFormCorrect = await this.v$.$validate();
      if (!isFormCorrect) return;
      else {
        let payload = {
          password: this.vmodel.password,
        };
        this.submitted = true;
        APIService.put(
          "/admin/generatepassword",
          payload,
          this.$store.state.idForResetPassword
        )
          .then((response) => {
            if (response && response.data && response.data.success) {
              this.$toast.add({
                severity: "success",
                summary: "Success",
                detail: response.data.message,
                life: 3000,
              });
              setTimeout(() => {
                this.$router.push({ name: "Login" });
              });
            } else {
              this.$toast.add({
                severity: "error",
                summary: "Error",
                detail: response.data.message,
                life: 3000,
              });
            }
          })
          .finally(() => {
            this.submitted = false;
          });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/css/login.css";
</style>
