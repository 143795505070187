<template>
  <div class="ml-6 mr-6 margin-top mb-6">
    <div class="grid">
      <div class="flex order-form-title">
        Questionnaire Management / {{ title }}
      </div>
      <div class="order-card w-full">
        <div class="col-12">
          <div class="grid">
            <div class="col-3">
              <div class="order-form-sub-title">Questionnaire Details</div>
            </div>
            <div class="col-9">
              <div class="grid">
                <div class="col-6">
                  <InputTextRequire
                    v-model="vmodel.name"
                    :validation="v$.vmodel.name"
                    sectionClass="field mt-1"
                    id="name"
                    label="Questionnaire Name"
                    placeholder="Questionnaire Name"
                    maxlength="50"
                  />
                </div>
                <div v-if="!isEditMode" class="col-6">
                  <DropDownRequire
                    v-model="vmodel.type"
                    :validation="v$.vmodel.type"
                    :options="productTypeOptions"
                    sectionClass="field mt-1"
                    id="type"
                    label="Product Type"
                    placeholder="Select Product Type"
                    optionLabel="name"
                    optionValue="id"
                  />
                </div>
                <div v-if="isEditMode" class="col-6">
                  <DropDownRequire
                    v-model="vmodel.type"
                    :validation="v$.vmodel.type"
                    :options="productTypeOptionsEdit"
                    sectionClass="field mt-1"
                    id="type"
                    label="Product Type"
                    placeholder="Select Product Type"
                    optionLabel="name"
                    optionValue="id"
                    :disabled="true"
                  />
                </div>
              </div>
              <div class="grid">
                <div class="col-12">
                  <InputTextRequire
                    v-model="vmodel.description"
                    :validation="v$.vmodel.description"
                    sectionClass="field mt-1"
                    id="description"
                    label="Description"
                    placeholder="Description"
                    maxlength="50"
                  />
                </div>
              </div>
              <div class="mt-4">
                <PrimeButton
                  label="Add"
                  class="order-form-button"
                  v-if="!isEditMode"
                  @click="createQuestionnaire"
                ></PrimeButton>
                <PrimeButton
                  label="Edit"
                  class="order-form-button"
                  v-else
                  @click="createQuestionnaire"
                ></PrimeButton>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { useVuelidate } from "@vuelidate/core";
import { required, maxLength } from "@vuelidate/validators";
import APIService from "@/services/api-service.js";

export default {
  setup() {
    return { v$: useVuelidate() };
  },
  data() {
    return {
      photo: "",
      submitted: false,
      isEditMode: false,
      title: "Add",
      productTypeOptions: [],
      productTypeOptionsEdit: [],
      vmodel: {
        name: "",
        type: "",
        description: "",
      },
    };
  },
  validations() {
    return {
      vmodel: {
        name: { required, max: maxLength(50) },
        type: { required },
        description: { required, max: maxLength(300) },
      },
    };
  },
  mounted() {
    if (this.$route.params.id) {
      this.title = "Edit";
      this.isEditMode = true;
      this.getQuestionnaireById();
    }
    this.getProductType();
    this.getProductTypeEdit();
  },
  methods: {
    async createQuestionnaire() {
      const isFormCorrect = await this.v$.$validate();
      if (!isFormCorrect) return;
      else {
        let payload = {
          name: this.vmodel.name,
          producttype: this.vmodel.type,
          description: this.vmodel.description,
        };
        this.submitted = true;
        if (this.$route.params.id) {
          APIService.put("/quationnarie", payload, this.$route.params.id)
            .then((response) => {
              if (response && response.data && response.data.success) {
                this.$toast.add({
                  severity: "success",
                  summary: "Success",
                  detail: response.data.message,
                  life: 3000,
                });
                setTimeout(() => {
                  this.$router.push({ name: "Questionnaire" });
                });
              } else {
                this.$toast.add({
                  severity: "error",
                  summary: "Error",
                  detail: response.data.message,
                  life: 3000,
                });
              }
            })
            .finally(() => {
              this.submitted = false;
            });
        } else {
          APIService.post("/quationnarie", payload)
            .then((response) => {
              if (response && response.data && response.data.success) {
                this.$toast.add({
                  severity: "success",
                  summary: "Success",
                  detail: response.data.message,
                  life: 3000,
                });
                setTimeout(() => {
                  this.$router.push({ name: "Questionnaire" });
                });
              } else {
                this.$toast.add({
                  severity: "error",
                  summary: "Error",
                  detail: response.data.message,
                  life: 3000,
                });
              }
            })
            .finally(() => {
              this.submitted = false;
            });
        }
      }
    },
    async getQuestionnaireById() {
      APIService.getById("/quationnarie", this.$route.params.id)
        .then((response) => {
          if (response && response.data && response.data.data) {
            this.vmodel.name = response.data.data.name;
            this.vmodel.type = response.data.data.producttype;
            this.vmodel.description = response.data.data.description;
          } else {
            this.$toast.add({
              severity: "error",
              summary: "Error",
              detail: response.data.message,
              life: 3000,
            });
            setTimeout(() => {
              this.$router.push({ name: "Questionnaire" });
            });
          }
        })
        .finally(() => {
          this.submitted = false;
        });
    },
    getProductType() {
      APIService.get("/producttype/admin/dropdown")
        .then((response) => {
          if (response && response.data && response.data.data) {
            let data = response.data.data;
            // console.log(data, 11111);
            this.productTypeOptions = data.filter(x => x.isassign == false).map(x => {
              return {id: x.id, name: x.name}
            })
            // console.log(this.productTypeOptions,2222);
          } else {
            this.$toast.add({
              severity: "error",
              summary: "Error",
              detail: response.data.message,
              life: 3000,
            });
          }
        })
        .finally(() => {
          this.submitted = false;
        });
    },
    getProductTypeEdit() {
      APIService.get("/producttype/admin/dropdown")
        .then((response) => {
          if (response && response.data && response.data.data) {
            this.productTypeOptionsEdit = response.data.data
          } else {
            this.$toast.add({
              severity: "error",
              summary: "Error",
              detail: response.data.message,
              life: 3000,
            });
          }
        })
        .finally(() => {
          this.submitted = false;
        });
    },
  },
};
</script>
