<!-- eslint-disable vue/multi-word-component-names -->
<template>
  <SidebarMenu />
  <div style="margin-left: 337px">
    <Navbar />
    <router-view></router-view>
  </div>
  <PrimeToast />
</template>

<script>
import SidebarMenu from "@/components/layout/sidebar-menu.vue"
import Navbar from "@/components/layout/navbar.vue";

export default {
  components: {
    SidebarMenu,
    Navbar,
  },
};
</script>